import React, { useState } from 'react';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { RotateLeft } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
// import { Trans } from 'react-i18next';
import { Trans, useTranslate } from '../../components/translations/Translate';
import CustomTextField from '../../components/inputs/CustomTextField';
import Connections, { ApiEndpoints } from '../../components/connections/Connections';
import useError from '../../components/validations/hooks/useError';
import useLoading from '../../components/loading/hooks/useLoading';
import SuccessBox from '../../components/validations/SuccessBox';
import CustomButton from '../../components/inputs/CustomButton';
import LoginLayout from './LoginLayout';
import Logo from '../../components/assets/images/logo.svg';
import CustomLink from '../../components/inputs/CustomLink';
import UrlEnums from '../../components/connections/enums/UrlEnums';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RecoverPassword() {
  const classes = useStyles();

  const { t } = useTranslate();
  const [values, setValues] = useState({
    email: '',
  });

  const [requestLinkSent, setRequestLinkSent] = useState(false);
  const { loading, Loading, setLoading } = useLoading();
  const validations = {
    email: {
      type: 'isEmail',
      text: 'email.notValid',
    },
  };

  const {
    setCustomError,
    isError,
    getActivateError,
    convertErrorArray,
  } = useError({
    values,
    validations,
  });

  if (requestLinkSent) {
    return (
      <SuccessBox
        text={t('requestLinkSent')}
      />
    );
  }

  const resetPassword = async () => {
    setCustomError(null);
    setLoading(true);
    const err = getActivateError();
    if (!err) {
      const res = await Connections.postRequest(
        ApiEndpoints.passwordResetRequest, {
          email: values.email,
        },
      );
      if (res.ok) {
        setRequestLinkSent(true);
      } else if (res.errorData && res.errorData.errors) {
        setCustomError(convertErrorArray(res.errorData.errors));
      } else {
        setCustomError({
          email: res.errorMessage,
        });
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  const handleChange = ({ name, value }) => {
    setValues({ ...values, [name]: value });
  };

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      resetPassword().then();
    }
  };

  return (
    <LoginLayout>
      <div className={classes.paper}>
        <div style={{ marginBottom: '4em' }}>
          <img src={Logo} alt="JUST-SELL.online" width={201} height={45} />
        </div>
        <Grid container direction="row" alignItems="center" justifyItems="flex-start">
          <Grid item xs={2}>
            <Avatar className={classes.avatar}>
              <RotateLeft />
            </Avatar>
          </Grid>
          <Grid item xs={10}>
            <Typography component="h1" variant="h5">
              {t('password.recovery')}
            </Typography>
            <Typography>
              <Trans i18nKey="password.welcome">
                Passwortreset <br /> blah blah
              </Trans>
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.form} noValidate>
          <CustomTextField
            name="email"
            id="email"
            label="email.address"
            autoComplete="email"
            value={values.email}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            type="email"
            autoFocus
            fullWidth
            required
            error={isError('email')}
          />
          <CustomButton
            fullWidth
            className={classes.submit}
            onClick={resetPassword}
          >
            {t('password.sendVerificationLink')}
          </CustomButton>
          <Grid
            container
            justify="center"
            alignContent="center"
            alignItems="center"
            className={classes.signUp}
          >
            <Grid item xs={12} align="center">
              <Typography>
                {t('password.justRemembered')}
                <CustomLink
                  to={UrlEnums.LOGIN}
                  text
                >
                  {t('login')}
                </CustomLink>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </LoginLayout>
  );
}
