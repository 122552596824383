import {
  atom,
} from 'recoil';

const globalStore = atom({
  key: 'globalStore',
  default: false,
});

const sidebarStore = atom({
  key: 'sidebarStore',
  default: {
    open: true,
    bimbom: 'bombom',
  },
});

export { globalStore, sidebarStore };
export default {};
