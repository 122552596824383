import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslate } from './Translate';
import { useSetLanguage } from '../../screens/users/hooks/languageHook';

function checkColor(color = '#000') {
  let result = null;
  if (color !== 'light' && color !== 'dark') {
    if (color.substr(0, 1) === '#' && (color.length === 4 || color.length === 7)) {
      result = color;
    }
  } else {
    result = color === 'light' ? '#fff' : '#000';
  }
  if (result === null) result = '#000';
  return result;
}

const LanguagesPicker = (props) => {
  const { color = 'light', mobileColor } = props;
  const lColor = checkColor(color);
  const mColor = checkColor(mobileColor);

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    select: {
      color: lColor,
      '&:before': {
        borderBottomColor: `${lColor} !Important`,
      },
      [theme.breakpoints.down('sm')]: {
        color: mColor,
        '&:before': {
          borderBottomColor: `${mColor} !Important`,
        },
      },
    },
    selectIcon: {
      color: lColor,
      [theme.breakpoints.down('sm')]: {
        color: mColor,
      },
    },
  }));
  const classes = useStyles();
  const changeLanguage = useSetLanguage();
  const { t, i18n } = useTranslate();
  const [lang, setLang] = useState(i18n.language);
  const handleChange = event => {
    changeLanguage(event.target.value);
    setLang(event.target.value);
  };
  const { language } = i18n;
  useEffect(() => {
    setLang(language);
  }, [language]);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          value={lang}
          onChange={handleChange}
          className={classes.select}
          classes={
            {
              icon: classes.selectIcon,
            }
          }
        >
          <MenuItem value="en">{t('EN')}</MenuItem>
          <MenuItem value="de">{t('DE')}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguagesPicker;
