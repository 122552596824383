import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import UrlEnums from '../../components/connections/enums/UrlEnums';
import CustomLink from '../../components/inputs/CustomLink';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: 'Basic Shop',
    price: '24',
    description: [
      '1 Language',
      '1 Currency',
      '1 Location',
      'Up to 2 Admin Accounts',
      'Up to 1 GB Capacity (~20000 Product Images)',
      'No Backups',
    ],
    buttonText: 'Order Now',
  },
  {
    title: 'Standard Shop',
    subheader: 'Most popular',
    price: '149',
    description: [
      'Up to 5 Languages',
      'Up to 5 Currencies',
      'Up to 5 Locations',
      'Up to 5 Admin Accounts',
      '5 GB Capacity (~100000 Product Images)',
      '1 Backup / Week',
    ],
    buttonText: 'Order Now',
  },
  {
    title: 'Enterprise',
    subheader: 'The Shop Giant',
    price: '349',
    description: [
      'Unlimited languages',
      'Unlimited currencies',
      'Unlimited locations',
      'Unlimited admin accounts',
      'Unlimited capacity',
      '2 Speedpages',
      'Daily Backup',
    ],
    buttonText: 'Order Now',
  },
];

export default function ShopPricing() {
  const classes = useStyles();

  return (
    <>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Pricing
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          Quickly build an effective pricing table for your potential customers with this layout.
          It&apos;s built with default Material-UI components with little customization.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary" style={{ fontSize: '140%' }}>
                      € {tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /Mo
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <CustomLink
                    button
                    style={{ width: '100%' }}
                    buttonProps={{ fullWidth: true }}
                    to={UrlEnums.ORDER_CHECKOUT}
                  >
                    {tier.buttonText}
                  </CustomLink>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
