import React, { useEffect } from 'react';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import i18n from './i18n';

export const t = (text, { format = null, ...params } = { format: null }) => (
  format ? i18next.format(i18n.t(text, params), format)
    : i18n.t(text, params));

export const useTranslate = (namespaces) => {
  const origTrans = useTranslation();
  useEffect(() => {
    if (namespaces?.length) {
      try {
        i18next.loadNamespaces(namespaces);
      } catch (e) {
        console.log('Error while trying to load namespace', e);
      }
    }
  }, []);
  return { t, i18n: origTrans.i18n, ready: origTrans.ready };
};

const Translate = ({ text, format = null, params }) => {
  const { t: tI18n } = useTranslation();
  return format ? <>{t(text, { format, ...params })}</> : tI18n(text, params);
};

export default Translate;

export { Trans };
