import React, { useState } from 'react';
import {
  Grid, IconButton, Tooltip, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { AddCircleOutline } from '@material-ui/icons';
import Connections, { ApiEndpoints } from '../../components/connections/Connections';
import useFetch from '../../components/connections/hooks/useFetch';
import useValuesDialog from '../../components/dialogs/hooks/useValuesDialog';
import Loading from '../../components/loading/Loading';
import useSnackbar from '../../components/snackbars/hooks/useSnackbar';
import ErrorText from '../../components/validations/ErrorText';
import ShopEnums from './enums/ShopEnums';
import MediaCard from '../../components/cards/MediaCard';
import UrlEnums from '../../components/connections/enums/UrlEnums';

const ShopSuy = () => {
  const { t } = useTranslation(['common', 'shops']);
  const { result, runFetch } = useFetch({ url: ApiEndpoints.getMyProjects });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  if (result.loading) return <Loading />;
  const { ValuesDialog, openDialog } = useValuesDialog();
  const { createSuccessSnackbar, createErrorSnackbar } = useSnackbar();
  const { projects = [] } = result.payload || {};

  const saveProject = async (pageParams) => {
    setError(null);
    setLoading(true);
    const res = await Connections.postRequest(ApiEndpoints.addProject, pageParams);
    if (res.ok) {
      createSuccessSnackbar(t('shops:successfullyCreatedShop'));
      runFetch();
    } else {
      createErrorSnackbar(t('shops:errorOnCreatingShop'));
      if (res.errorMessage) {
        setError(res.errorMessage);
      }
    }
    setLoading(false);
  };

  const addProject = () => {
    openDialog({
      title: t('shops:modal.title'),
      text: t('shops:modal.enterDataForYourNewShop'),
      defaultValues: {
        type: ShopEnums.SHOP,
        name: '',
        description: '',
        email: '',
        password: '',
      },
      validations: {
        email: {
          type: 'isEmail',
        },
        password: {
          type: 'isPassword',
        },
        name: {
          type: 'isEmpty',
        },
        description: {
          type: 'isEmpty',
        },
      },
      inputs: [
        {
          inputType: 'text',
          props: {
            name: 'name',
            label: t('shops:modal.name'),
            fullWidth: true,
            required: true,
          },
        },
        {
          inputType: 'text',
          props: {
            name: 'description',
            label: t('shops:modal.description'),
            fullWidth: true,
            required: true,
          },
        },
        {
          inputType: 'text',
          props: {
            name: 'email',
            label: t('email'),
            fullWidth: true,
            required: true,
          },
        },
        {
          inputType: 'text',
          props: {
            name: 'password',
            label: t('password'),
            type: 'password',
            fullWidth: true,
            required: true,
          },
        },
      ],
    },
    projectValues => {
      saveProject(projectValues).then();
    });
  };

  return (
    <div>
      <Helmet>
        <title>{t('shops:pageTitle')}</title>
        <meta name="description" content={t('shops:pageTitle')} />
      </Helmet>

      <Grid
        container
        justify="space-between"
      >
        <Grid item xs={6}>
          <br />
          <Typography variant="h1">{t('shops:pageTitle')}</Typography>
        </Grid>
        <Grid />
        <Grid item xs={2}>
          <Grid container justify="flex-end">
            <Tooltip title={t('shops:shops:newProject')} placement="left">
              <IconButton onClick={addProject} color="secondary">
                <AddCircleOutline fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {loading && <Loading />}
      {error && <ErrorText error={error} />}
      {ValuesDialog}
      <Grid container spacing={3}>
        {projects.map(w => w.type.toLowerCase() === 'shop' && (
          <Grid key={w.projectId} item xs={12} sm={12} md={6} lg={4}>
            <MediaCard
              {...w}
              url={w.virtual ? `${UrlEnums.VIEW_SHOP_BASE}/${w.projectId}` : w.url}
              menu={
                [
                  {
                    type: 'link',
                    to: `${UrlEnums.VIEW_SHOP_BASE}/${w.projectId}`,
                    label: t('edit'),
                  },
                  {
                    type: 'link',
                    to: '/login',
                    label: t('settings'),
                  },
                  { type: 'divider' },
                  {
                    type: 'label',
                    to: '/login',
                    label: `${t('deactivate')}`,
                  },
                ]
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ShopSuy;
