import { Avatar, Divider, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function SidebarHeader({
  image = null,
  line1,
  line2,
  collapsed = false,
}) {
  const useStyles = makeStyles(theme => ({
    avatarWrapper: {
      padding: 20,
      textAlign: 'center',
    },
    avatarLarge: {
      margin: 'auto',
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  }));
  const classes = useStyles();
  return (
    collapsed
      ? <div />
      : (
        <>
          <Grid container alignContent="center" direction="column" className={classes.avatarWrapper}>
            {image !== null && (
            <Avatar className={classes.avatarLarge}>
              <img src={image} alt={line1} />
            </Avatar>
            )}
            <Typography><strong>{line1}</strong></Typography>
            <Typography><small>{line2}</small></Typography>
          </Grid>
          <Divider />
        </>
      )
  );
}
