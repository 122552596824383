import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomLink from '../../inputs/CustomLink';
import Logo from '../../assets/images/logo.svg';
import { useTranslate } from '../../translations/Translate';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logo: {
    height: 30,
    width: 'auto',
    marginLeft: 10,
    marginTop: 7,
  },
}));

const HeaderLogo = () => {
  const classes = useStyles();
  const { t } = useTranslate();
  return (
    <CustomLink plain to="/">
      <img src={Logo} alt={t('app.title')} className={classes.logo} />
    </CustomLink>
  );
};

export default HeaderLogo;
