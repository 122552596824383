const UrlEnums = {
  MAIN: '/',
  PROFILE: '/profile',
  SPEEDPAGES: '/speedpages',
  SHOPS: '/shops',
  ADMIN: '/admin',
  LOGIN: '/login',
  SIGN_UP: '/signUp',
  LOGOUT: '/logout',
  PASSWORD_RECOVERY: '/passwordRecovery',

  VIEW_SPEEDPAGE_BASE: '/previewSpeedPage',
  VIEW_SPEEDPAGE: '/previewSpeedPage/:projectId',
  VIEW_SHOP_BASE: '/preview',
  VIEW_SHOP: '/preview/:projectId',

  ORDER_SHOP_BASE: '/order',
  ORDER_SHOP: '/order/:projectId',
  ORDER_CHECKOUT: '/checkout',

  PRIVACY_POLICY: 'https://gastro.just-sell.online/datenschutz',
  TERMS: 'https://just-sell.online/terms',
  HELP: 'https://help.just-sell.online',
  IMPRINT: 'https://gastro.just-sell.online/impressum',
};
export default UrlEnums;
