import React from 'react';

import { Button, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UrlEnums from '../connections/enums/UrlEnums';
import { useTranslate } from '../translations/Translate';

const useStyles = makeStyles(() => ({
  buttonInGroup: {
    fontWeight: 'normal',
    fontSize: 'smaller',
    color: 'gray',
  },
}));

export default function LegalButtonGroup() {
  const { t } = useTranslate();
  const classes = useStyles();

  return (
    <ButtonGroup variant="text" size="small" aria-label="small text button group">
      <Button className={classes.buttonInGroup} href={UrlEnums.IMPRINT}>{t('legal.imprint')}</Button>
      <Button className={classes.buttonInGroup} href={UrlEnums.PRIVACY_POLICY}>{t('legal.privacy')}</Button>
      {/* <Button className={classes.buttonInGroup} href={UrlEnums.TERMS}>{t('legal.terms')}</Button> */}
    </ButtonGroup>
  );
}
