import React from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from '../inputs/CustomButton';
import useValuesContent from '../dataHandling/hooks/useValuesContent';
import { useTranslate } from '../translations/Translate';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
  },
  title: {
    paddingTop: theme.spacing(3),
  },
  dialogPadding: {
    padding: theme.spacing(3),
  },
  actionRow: {
    padding: theme.spacing(2),
  },
  text: {
    //  textAlign: 'center',
  },
}));

const ValuesDialog = ({
  title,
  text,
  open,
  defaultValues,
  validations,
  inputs = [],
  onClose,
  onSave,
}) => {
  const classes = useStyles();
  const { t } = useTranslate();
  const {
    values,
    Content,
    getActivateError,
    deactivateError,
  } = useValuesContent({ inputs, defaultValues, validations });

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="input-dialog"
      fullWidth
      maxWidth="sm"
    >
      <div className={classes.dialogPadding}>
        <DialogTitle id="input-dialog" className={classes.title}>
          <Typography variant="h1">{title}</Typography>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.text} variant="subtitle1">
            {text}
          </Typography>
          {Content}
        </DialogContent>
        <DialogActions className={classes.actionRow}>
          <CustomButton
            onClick={() => {
              const error = getActivateError();
              if (!error) {
                onClose();
                onSave(values);
                deactivateError();
              }
            }}
            color="secondary"
            className={classes.button}
          >
            {t('save')}
          </CustomButton>
          <CustomButton
            variant="outlined"
            onClick={() => onClose()}
            color="secondary"
            className={classes.button}
          >
            {t('cancel')}
          </CustomButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};
export default ValuesDialog;
