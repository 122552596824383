import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { CardHeader, Divider, Tooltip } from '@material-ui/core';
import { AccessTime, OpenInNew } from '@material-ui/icons';
import { useTranslate } from '../translations/Translate';
import CustomLink from '../inputs/CustomLink';
import DateHelper from '../helpers/DateHelper';
import CardMenu from '../menu/CardMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 270,
  },
  media: {
    height: 150,
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 12,
  },
  nonLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    alignSelf: 'center',
  },
  nonLinkIcon: {
    marginRight: theme.spacing(1),
  },
}));

/**
 * Media Card for pages.
 * @param {Object}  props
 * @param {string}  props.name - Name of the Project
 * @param {string}  props.description - description of the Project
 * @param {Object=} props.footerComponent - Component for Footer
 * @param {array=}  props.menu - array with card-menu
 * @example
 *  [
 *   {
 *     type: 'link',
 *     to: '/login',
 *     label: t('edit'),
 *   },
 *   {
 *     type: 'label',
 *     label: "test123",
 *   },
 *   { type: 'divider' },
 *  ]
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function MediaCard(props) {
  const { t } = useTranslate(['common', 'dashboard']);
  const classes = useStyles();
  const {
    name, description, menu = [], footerComponent = null, url,
  } = props;
  const { updatedAt } = props;
  const { label, value } = DateHelper.elapsedTimeAsFormatedString(updatedAt);

  return (
    <>
      <Card className={classes.root} variant="outlined">
        <CardHeader
          action={menu.length > 0
            ? (
              <CardMenu menu={menu} />
            ) : null}
          title={name}
        />
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image="https://www.suther.de/uploads/RTEmagicC_scuderia_ferrari_club_01.jpg.jpg"
            title={name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
              {
              // TODO: Open pulldown with stuff from props.configuration:
              //  https://material-ui.com/components/cards/#complex-interaction
            }
            </Typography>
          </CardContent>
        </CardActionArea>
        <Divider />
        <CardActions className={classes.cardFooter}>
          { footerComponent || (
          <>
            <div className={classes.nonLink}>
              <AccessTime className={classes.nonLinkIcon} />
              {t('dashboard:card.updated', { count: value, label: `$t(common:date.${label})` })}
            </div>
            {/* TODO: Only show if Webpage was build and is currently live! */}
            <CustomLink type="text" size="small" to={url} target="_blank">
              <Tooltip title={t('dashboard:card.preview')}>
                <OpenInNew />
              </Tooltip>
            </CustomLink>
          </>
          )}
        </CardActions>
      </Card>
    </>
  );
}
