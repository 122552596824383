import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslate } from '../components/translations/Translate';
import Dashboard from './dashboard/Dashboard';

const Home = () => {
  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title>{t('app.title')} | Home</title>
        <meta name="description" content={`${t('app.description')} | Home`} />
      </Helmet>
      <Dashboard />
    </>
  );
};

export default Home;
