import { Box, Container } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomLink from '../../inputs/CustomLink';

const Footer = ({ href, children, className = '' }) => {
  const classes = makeStyles(() => ({
    text: { color: 'gray', ...className },
  }))();
  return (
    <Container>
      <Box m={1} align="center">
        <small className={classes.text}>
          {'Copyright © '}{new Date().getFullYear()}{' '}
          <CustomLink
            rel="noreferrer"
            plain
            target="_blank"
            href={href}
            className
          >
            {children}
          </CustomLink>
        </small>
      </Box>
    </Container>
  );
};
export default Footer;
