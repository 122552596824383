/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import Container from '@material-ui/core/Container';
import { ApiEndpoints } from '../../components/connections/Connections';
import useFetch from '../../components/connections/hooks/useFetch';
import useValuesDialog from '../../components/dialogs/hooks/useValuesDialog';
import Loading from '../../components/loading/Loading';
import ErrorText from '../../components/validations/ErrorText';

const Dashboard = () => {
  const { t } = useTranslation(['dashboard']);
  const { result, runFetch } = useFetch({ url: ApiEndpoints.getMyProjects });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  if (result.loading) return <Loading />;
  const { ValuesDialog, openDialog } = useValuesDialog();

  return (
    <div>
      <Helmet>
        <title>{t('dashboard:pageTitle')}</title>
        <meta name="description" content={t('dashboard:pageTitle')} />
      </Helmet>
      <Grid
        container
        justify="space-between"
      >
        <Grid item xs={6}>
          <br />
          <Typography variant="h1">{t('dashboard:pageTitle')}</Typography>
        </Grid>
        <Grid />
        <Grid item xs={2}>
          <Grid container justify="flex-end" />
        </Grid>
      </Grid>
      {loading && <Loading />}
      {error && <ErrorText error={error} />}
      {ValuesDialog}
      <Grid container spacing={3}>
        <Container>
          Hier kommt ein schönes Dashboard rein ;)<br />        Willst du was schönes sehen, klicke auf "SpeedPages"

        </Container>
      </Grid>
    </div>
  );
};

export default Dashboard;
