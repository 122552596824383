import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DemoShopViewer from '../../screens/vizualizer/DemoShopViewer';
import DemoSpeedPageViewer from '../../screens/vizualizer/DemoSpeedPageViewer';
import UrlEnums from '../connections/enums/UrlEnums';
import Home from '../../screens/Home';
import Public from './Public';
import Login from '../../screens/auth/Login';
import NotFoundPage from '../../screens/NotFoundPage';
import Logout from '../../screens/auth/Logout';
import SignUp from '../../screens/auth/SignUp';
import RecoverPassword from '../../screens/auth/RecoverPassword';
import Authenticated from './Authenticated';
import OrderProject from '../../screens/ordering/OrderProject';
import Checkout from '../../screens/ordering/checkout/Checkout';
import SpeedPages from '../../screens/speedPages/SpeedPages';
import Shops from '../../screens/shops/Shops';

const MainRoutes = () => (
  <Switch>
    <Authenticated exact path={UrlEnums.MAIN} component={Home} />
    <Authenticated exact path={UrlEnums.VIEW_SHOP} component={DemoShopViewer} />
    <Authenticated exact path={UrlEnums.VIEW_SPEEDPAGE} component={DemoSpeedPageViewer} />
    <Authenticated exact path={UrlEnums.ORDER_SHOP} component={OrderProject} />
    <Authenticated exact path={UrlEnums.ORDER_CHECKOUT} component={Checkout} />
    <Authenticated exact path={UrlEnums.SPEEDPAGES} component={SpeedPages} />
    <Authenticated exact path={UrlEnums.SHOPS} component={Shops} />
    <Public exact path={UrlEnums.LOGIN} component={Login} />
    <Public exact path={UrlEnums.SIGN_UP} component={SignUp} />
    <Public exact path={UrlEnums.PASSWORD_RECOVERY} component={RecoverPassword} />
    <Authenticated exact path={UrlEnums.LOGOUT} component={Logout} />
    <Route path="*" component={NotFoundPage} />
  </Switch>
);

export default MainRoutes;
