export default class DateHelper {
  static setBeginningOfDay(date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );
  }

  static setBeginningOfHour(date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
    );
  }

  static setFirstDayOfMonth(date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
    );
  }

  static getNow() {
    return new Date(Date.now());
  }

  static getBefore(dateObject) {
    return new Date(Date.now() - this.getDateBase(dateObject));
  }

  static getAfter(dateObject) {
    return new Date(Date.now() + this.getDateBase(dateObject));
  }

  static getDateAt(dateObject) {
    return new Date(this.getDateBase(dateObject));
  }

  static getDateToDay(dateObject) {
    return new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate());
  }

  static getTimestamp() {
    return Date.now();
  }

  static getTimestampBefore(dateObject) {
    return this.getTimestamp() - this.getDateBase(dateObject);
  }

  static getTimestampAfter(dateObject) {
    return this.getTimestamp() + this.getDateBase(dateObject);
  }

  /**
   * get elapsed milliseconds between two dates. If second parameter is not set, "Date.now()" will be used for it
   * Parameters can be paste as String "2021-02-25T14:48:22.684Z", or as valid JS Date-Object (new Date())
   * @param fromDate {String|Date}
   * @param toDate {String|Date}
   */
  static elapsedTimeInMilliseconds(fromDate, toDate = null) {
    const date1 = fromDate instanceof Date ? fromDate : new Date(fromDate);
    let date2;
    if (toDate === null) { date2 = new Date(); } else {
      date2 = toDate instanceof Date ? toDate : new Date(toDate);
    }
    const arr = [date1.getTime(), date2.getTime()].sort((a, b) => b - a);
    return arr[0] - arr[1];
  }

  /**
   * convert Milliseconds into Object splitted up by sec, min, hours days and years
   * @param ms
   * @return {{hours: number, seconds: number, minutes: number, days: number}}
   */
  static ms2Time(ms) {
    let seconds = ms / 1000;
    let minutes = seconds / 60;
    seconds = Math.floor(seconds % 60);
    let hours = minutes / 60;
    minutes = Math.floor(minutes % 60);
    let days = hours / 24;
    hours = Math.floor(hours % 24);
    let years = days / 365;
    days = Math.floor(days);
    years = Math.floor(years);
    return {
      years,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  static elapsedTimeAsFormatedString(fromDate, toDate = null) {
    const calculateDate = this.elapsedTimeInMilliseconds(fromDate, toDate);
    const resultObj = this.ms2Time(calculateDate);
    const result = {};
    let foundOne = false;
    Object.keys(resultObj).forEach((key) => {
      if (resultObj[key] > 0 && !foundOne) {
        result.value = resultObj[key];
        result.label = key.substr(0, key.length - 1); // you must do singular/plural by i18n and {count}
        foundOne = true;
      }
    });
    return result;
  }

  static getDateBase({
    seconds = 0,
    minutes = 0,
    hours = 0,
    days = 0,
    weeks = 0,
    months = 0,
    years = 0,
  }) {
    return (
      this.getSecondsBase(seconds) +
      this.getMinutesBase(minutes) +
      this.getHoursBase(hours) +
      this.getDaysBase(days) +
      this.getWeeksBase(weeks) +
      this.getMonthsBase(months) +
      this.getYearsBase(years)
    );
  }

  static getSecondsBase(seconds = 0) {
    return seconds * 1000;
  }

  static getMinutesBase(minutes = 0) {
    return minutes * 60 * 1000;
  }

  static getHoursBase(hours = 0) {
    return hours * 60 * 60 * 1000;
  }

  static getDaysBase(days = 0) {
    return days * 24 * 60 * 60 * 1000;
  }

  static getWeeksBase(weeks = 0) {
    return weeks * 7 * 24 * 60 * 60 * 1000;
  }

  static getMonthsBase(months = 0) {
    return months * 30 * 24 * 60 * 60 * 1000;
  }

  static getYearsBase(years = 0) {
    return years * 365 * 24 * 60 * 60 * 1000;
  }
}
