import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import Authorized from '../../../screens/auth/Authorized';
import Drawer from '../sidebar/Drawer';
import { MobileMenu, MobileMenuSection } from '../../menu/MobileMenu';
import { ProfileMenu, DesktopMenuSection } from '../../menu/DesktopMenu';
import HeaderLogo from './HeaderLogo';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: 'rgb(15,200,98)',
    background: 'linear-gradient(140deg, #fcfcfc 70px, ' +
        'rgba(121,203,136,1) 70%, ' +
        'rgba(125,203,138,1) 80%, ' +
        '#179c53 100%)',
    [theme.breakpoints.up('md')]: {
      background: 'linear-gradient(140deg, #fcfcfc 20%, ' +
        'rgba(121,203,136,1) 50%, ' +
        'rgba(125,203,138,1) 67%, ' +
        '#179c53 86%)',
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar, // necessary for content to be below app bar
  },
}));

export default function Header() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <HeaderLogo />
          {/* <Authorized authenticated>
            <HeaderSearch />
          </Authorized> */}
          <div className={classes.root} />
          <DesktopMenuSection
            handleProfileMenuOpen={handleProfileMenuOpen}
          />
          <MobileMenuSection
            handleMobileMenuOpen={handleMobileMenuOpen}
          />
        </Toolbar>
      </AppBar>
      <Authorized authenticated>
        <Drawer />
      </Authorized>
      <MobileMenu
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        handleMobileMenuClose={handleMobileMenuClose}
        handleProfileMenuOpen={handleProfileMenuOpen}
      />
      <ProfileMenu
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
      />
    </div>
  );
}
