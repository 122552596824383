import React, { useEffect } from 'react';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WbSunny } from '@material-ui/icons';

import { useTranslate } from '../../components/translations/Translate';
import Connections, { ApiEndpoints } from '../../components/connections/Connections';
import tokenStore from '../../components/connections/stores/tokenStore';
import { useLogoutUser } from '../users/hooks/userDataHooks';
import LoginLayout from './LoginLayout';
import Logo from '../../components/assets/images/logo.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },

}));

const Logout = () => {
  const logout = useLogoutUser();
  const classes = useStyles();
  const { t } = useTranslate();

  useEffect(() => {
    const logoutUser = async () => {
      await Connections.postRequest(ApiEndpoints.logout);
    };
    logoutUser().then(() => {
      tokenStore.remove();
    });
    logout(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginLayout>
      <div className={classes.paper}>
        <div style={{ marginBottom: '4em' }}>
          <img src={Logo} alt="JUST-SELL.online" width={201} height={45} />
        </div>
        <Grid container direction="row" alignItems="center" justifyItems="flex-start">
          <Grid item xs={2}>
            <Avatar className={classes.avatar}>
              <WbSunny />
            </Avatar>
          </Grid>
          <Grid item xs={10}>
            <Typography component="h1" variant="h5">
              {t('logout.title')}
            </Typography>
            <Typography>
              {t('logout.text')}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </LoginLayout>
  );
};

export default Logout;
