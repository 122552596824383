import { EmojiEvents } from '@material-ui/icons';
import React, { useState } from 'react';
import {
  Avatar,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslate } from '../../components/translations/Translate';
import CustomLink from '../../components/inputs/CustomLink';
import CustomTextField from '../../components/inputs/CustomTextField';
import Connections, { ApiEndpoints } from '../../components/connections/Connections';
import CustomCheckBox from '../../components/inputs/CustomCheckBox';
import UrlEnums from '../../components/connections/enums/UrlEnums';
import useError from '../../components/validations/hooks/useError';
import useLoading from '../../components/loading/hooks/useLoading';
import SuccessBox from '../../components/validations/SuccessBox';
import Logo from '../../components/assets/images/logo.svg';
import CustomButton from '../../components/inputs/CustomButton';
import LoginLayout from './LoginLayout';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function SignUp() {
  const classes = useStyles();

  const { t } = useTranslate();
  const [values, setValues] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    firstName: '',
    lastName: '',
    terms: false,
  });

  const validations = {
    email: {
      type: 'isEmail',
      text: 'errorDescription.email',
    },
    password: {
      type: 'isEmpty',
      text: 'errorDescription.password',
    },
    repeatPassword: {
      customValidation: () => values.password === values.repeatPassword,
      text: 'password.shouldMatch',
    },
    terms: {
      type: 'isTrue',
      text: 'field.required',
    },
  };

  const [signUpCompleted, setSignUpCompleted] = useState(false);
  const { loading, Loading, setLoading } = useLoading();

  const {
    setCustomError,
    isError,
    getActivateError,
    convertErrorArray,
  } = useError({
    values,
    validations,
  });

  if (signUpCompleted) {
    return (
      <SuccessBox
        text="signUp.successfulRegistration"
      />
    );
  }

  const signUp = async () => {
    setCustomError(null);
    const err = getActivateError();
    if (!err) {
      setLoading(true);
      const res = await Connections.postRequest(ApiEndpoints.signUp, {
        email: values.email,
        password: values.password,
      });

      if (res.ok) {
        setSignUpCompleted(true);
      } else if (res.errorCode === 'USER_ALREADY_EXISTS') {
        setCustomError({ email: 'user.alreadyExists' });
      } else if (res.errorData && res.errorData.errors) {
        setCustomError(convertErrorArray(res.errorData.errors));
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleChange = ({ name, value }) => {
    setValues({ ...values, [name]: value });
  };

  if (loading) return <Loading />;
  return (
    <LoginLayout>
      <div className={classes.paper}>
        <div style={{ marginBottom: '4em' }}>
          <img src={Logo} alt="JUST-SELL.online" width={201} height={45} />
        </div>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <div>
            <Avatar className={classes.avatar}>
              <EmojiEvents />
            </Avatar>
          </div>
          <div>
            <Typography component="h1" variant="h5">
              {t('signUp.title')}
            </Typography>
            <Typography>
              {t('signUp.welcome')}
            </Typography>
          </div>
        </Grid>
        <form className={classes.form} noValidate>
          <CustomTextField
            id="email"
            name="email"
            label="email.address"
            autoComplete="email"
            value={values.email}
            onChange={handleChange}
            type="email"
            autoFocus
            fullWidth
            required
            error={isError('email')}
          />
          <CustomTextField
            id="password"
            name="password"
            label="password"
            autoComplete="current-password"
            value={values.password}
            onChange={handleChange}
            type="password"
            fullWidth
            required
            error={isError('password')}
          />
          <CustomTextField
            id="repeatPassword"
            name="repeatPassword"
            label="repeatPassword"
            value={values.repeatPassword}
            onChange={handleChange}
            type="password"
            fullWidth
            required
            error={isError('repeatPassword')}
          />
          <CustomCheckBox
            label={t('signUp.newsletter')}
            name="terms"
            onChange={handleChange}
            error={isError('newsletter')}
          />
          <CustomCheckBox
            label={(
              <Trans
                i18nKey="legal.termsCheckbox"
              >
                PlatzhalterText für I18n Ersetzung...
                <CustomLink
                  to={UrlEnums.PRIVACY_POLICY}
                  target="_blank"
                >{{ privacyPolicyLink: t('legal.privacy') }}
                </CustomLink>
                PlatzhalterText für I18n Ersetzung...
              </Trans>
                  )}
            name="terms"
            onChange={handleChange}
            error={isError('terms')}
          />
          <CustomButton
            fullWidth
            className={classes.submit}
            onClick={signUp}
          >
            {t('signUp')}
          </CustomButton>
          <Grid
            container
            justify="center"
            alignContent="center"
            alignItems="center"
            className={classes.signUp}
          >
            <Grid item xs={12} align="center">
              <Typography>
                {t('login.alreadyAccount')}
                <CustomLink
                  to={UrlEnums.LOGIN}
                  text
                >
                  {t('login')}
                </CustomLink>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </LoginLayout>
  );
}
