import { useState, useEffect } from 'react';
import Connections from '../Connections';

const defaultState = {
  payload: null,
  success: false,
  finished: false,
};

const defaultCacheData = {
  timeout: { minutes: 0 },
};

const useFetch = ({
  url,
  params,
  ignoreCache = true,
  disableCache = true,
  dependency = [],
  cacheData = {},
}) => {
  const [errorData, setErrorData] = useState(null);

  const [result, setResult] = useState(defaultState);

  const [loading, setLoading] = useState(false);

  const cacheDataToUse = {
    ...defaultCacheData,
    ...cacheData,
  };

  const fetchData = async (forceUpdate = false) => {
    setLoading(true);
    setErrorData(null);

    const res = await Connections.postRequest(
      url,
      params,
      disableCache ? null : {
        ...cacheDataToUse,
        ignoreCache: forceUpdate,
      },
    );

    if (res.ok) {
      setResult({
        success: true,
        payload: res.data,
        finished: true,
      });
    } else {
      setResult({
        finished: true,
        success: false,
        payload: null,
      });
      if (res.errorData) {
        setErrorData(res.errorData);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(ignoreCache).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependency);

  const runFetch = (forceUpdate = true) => {
    fetchData(forceUpdate).then();
  };

  return {
    result,
    loading,
    setLoading,
    errorData,
    runFetch,
  };
};

export default useFetch;
