import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowForwardIos } from '@material-ui/icons';

import { useTranslate } from '../../components/translations/Translate';
import Connections, { ApiEndpoints } from '../../components/connections/Connections';
import UrlEnums from '../../components/connections/enums/UrlEnums';
import DateHelper from '../../components/helpers/DateHelper';
import CustomLink from '../../components/inputs/CustomLink';
import Loading from '../../components/loading/Loading';
import BasicConfig from '../../components/config/BasicConfig';

const useStyles = makeStyles({
  corner: {
    width: '0',
    height: '0',
    borderTop: '26px solid #128144',
    borderBottom: '0 solid transparent',
    borderLeft: '26px solid transparent',
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 2010,
    cursor: 'pointer',
    '&:hover': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRadius: '3px 2px 3px 20px',
      backgroundColor: '#128144',
      width: 160,
      height: 120,
      textAlign: 'center',
      color: '#FFF',
      padding: '0 0 0 9px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
    },
  },
  cornerSpan: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'bold',
  },
});

const DemoShopViewer = ({ match }) => {
  const classes = useStyles();
  const iframeRef = useRef(null);
  const { t } = useTranslate();
  const { params } = match;
  const { projectId } = params; // FIXME: Check for valid Project-ID

  const [localSettings, setLocalSettings] = useState(null);
  const [source, setSource] = useState(`${BasicConfig.VISUALIZER_SHOP}?themePreview=true`);
  const [loading, setLoading] = useState(true);
  const [iframeMounted, setIframeMounted] = useState(false);

  const activateSettings = async () => {
    if (!iframeRef.current) return;
    const { contentWindow = {} } = iframeRef.current || {};
    contentWindow.postMessage({
      action: 'saveTemporaryData',
      data: localSettings,
    }, '*');
    setSource(`${BasicConfig.VISUALIZER_SHOP}?themePreview=${DateHelper.getTimestamp()}`);
    setLoading(false);
  };

  const getSettings = async () => {
    setLoading(true);
    const res = await Connections.postRequest(ApiEndpoints.getProjectSettings, { projectId });
    if (res.ok) {
      const { settings } = res.data;
      setLocalSettings(settings);
    }
  };

  useEffect(() => {
    if (localSettings && iframeMounted) activateSettings().then();
  }, [localSettings, iframeMounted]);

  useEffect(() => {
    getSettings().then();

    return () => {
      if (iframeRef.current) {
        const { contentWindow = {} } = iframeRef.current || {};
        contentWindow.postMessage({
          action: 'deleteTemporaryData',
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <CustomLink to={`${UrlEnums.ORDER_SHOP_BASE}/${projectId}`} plain>
          <div className={classes.corner}>
            <div className={classes.cornerSpan}>{t('orderAndStartSelling')}</div>
            <ArrowForwardIos fontSize="large" />
          </div>
        </CustomLink>
      )}
      <div style={{
        width: loading ? 1 : '100%',
        height: loading ? 1 : '100%',
        zIndex: 2000,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'white',
      }}
      >
        <iframe
          ref={iframeRef}
          title="shopPreview"
          src={source}
          seamless
          width="100%"
          height="100%"
          frameBorder="0"
          onLoad={() => setIframeMounted(true)}
        />
      </div>
    </>
  );
};

export default DemoShopViewer;
