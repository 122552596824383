import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import CustomLink from '../inputs/CustomLink';
import { useTranslate } from '../translations/Translate';

const useStyles = makeStyles((theme) => ({
  menuItem: { justifyContent: 'flex-end' },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  mousePointer: {
    cursor: 'pointer',
  },
}));

const menuId = 'primary-search-account-menu';
/**
 *
 * @param {Object}  menu
 * @param {string}  menu.type   - (divider | label | link)
 * @param {string=} menu.to     - route which should be called
 * @param {string=} menu.target - target to open the link (default = '_self'
 * @param {string=} menu.label  - text to display as Link or Label
 * @param {string=} uniqueKey   - unique key for internal react-reference
 * @return {JSX.Element}
 */
const CardMenu = ({
  menu,
  uniqueKey,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);
  const { t } = useTranslate();
  return (
    <>
      <MoreVert onClick={handleClick} className={classes.mousePointer} />
      <Menu
        anchorPosition={{
          top: 0,
          left: 0,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {
            menu.map((menuItem) => {
              const {
                type,
                target = '_self',
                ...linkItem
              } = menuItem;
              if (type === 'divider') { return <Divider key={`${uniqueKey}`} />; }
              if (type === 'link') {
                const {
                  to,
                  label,
                } = linkItem;
                return (
                  <CustomLink key={`${uniqueKey}_${label}`} plain to={to} target={target}>
                    <MenuItem onClick={handleMenuClose} className={classes.menuItem}>{t(label)}</MenuItem>
                  </CustomLink>
                );
              }
              if (type === 'label') {
                const { label } = linkItem;
                return (
                  <div key={`${uniqueKey}_${label}`}>
                    <MenuItem key={`${uniqueKey}_${label}`} onClick={handleMenuClose}>{t(label)}</MenuItem>
                    <Divider />
                  </div>
                );
              }
              return {};
            })
          }
      </Menu>
    </>
  );
};

export default CardMenu;
