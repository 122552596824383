import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import Header from './header/Header';
import Footer from './footer/Footer';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    backgroundColor: theme.palette.body.background,
    minHeight: '100vh',
  },
  container: {
    display: 'grid',
    gridTemplateRows: '1fr',
  },
  gridContainer: {
    marginTop: 84,
    [theme.breakpoints.down('xs')]: {
      marginTop: 56,
    },
  },
}));

const BasicLayout = ({ children }) => {
  const location = useLocation();

  function checkRegExp(arr) {
    return arr.some((regexp) => regexp.test(location.pathname));
  }

  const classes = useStyles();
  const noLayout = checkRegExp([/^\/login/, /^\/signUp/, /^\/passwordRecovery/, /^\/logout/]);
  const fullSizeContent = checkRegExp([/^\/previewSpeedPage/]);

  return noLayout ? (
    <>{children}</>
  )
    : (
      <div className={classes.wrapper}>
        <Header />
        <Grid
          container
          direction="row"
          className={classes.gridContainer}
        >
          <Container maxWidth={fullSizeContent ? null : 'lg'} className={classes.container}>
            {children}
            <Footer
              href="https://just-sell.online"
              className={{ fontSize: 'smaller' }}
            >
              JUST-SELL.online
            </Footer>
          </Container>
        </Grid>
      </div>
    );
};

export default BasicLayout;
