import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Language } from '@material-ui/icons';
import LanguagesPicker from '../../components/translations/LanguagesPicker';
import LegalButtonGroup from '../../components/inputs/LeagalButtonGroup';
import Footer from '../../components/layout/footer/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/collection/97302462)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

export default function LoginLayout({ children }) {
  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} lg={8} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        lg={4}
        component={Paper}
        elevation={6}
      >
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="row"
            style={{ backgroundColor: '#efefef' }}
          >
            <div><Language /></div><LanguagesPicker color="dark" />
          </Grid>
          <Grid item>
            {children}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          alignContent="center"
          justify="center"
        >
          <LegalButtonGroup />
          <Footer
            href="https://just-sell.online"
            className={{ fontSize: 'smaller' }}
          >JUST-SELL.online
          </Footer>
        </Grid>
      </Grid>
    </Grid>
  );
}
