import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './EN/common.json';
import enDashboard from './EN/dashboard.json';
import enNavigation from './EN/navigation.json';
import enSpeedPages from './EN/speedPages.json';
import enShops from './EN/shops.json';

import de from './DE/common.json';
import deDashboard from './DE/dashboard.json';
import deNavigation from './DE/navigation.json';
import deSpeedPages from './DE/speedPages.json';
import deShops from './DE/shops.json';

i18n.use(initReactI18next).init(
  {
    ns: ['common', 'navigation', 'dashboard', 'speedPages', 'shops'],
    defaultNS: 'common',
    lng: 'de',
    fallbackLng: 'en',
    keySeparator: false,
    resources: {
      en: {
        common: en,
        navigation: enNavigation,
        dashboard: enDashboard,
        speedPages: enSpeedPages,
        shops: enShops,
      },
      de: {
        common: de,
        navigation: deNavigation,
        dashboard: deDashboard,
        speedPages: deSpeedPages,
        shops: deShops,
      },
    },
    interpolation: {
      escapeValue: false,
      // eslint-disable-next-line no-unused-vars
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize') return `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`;
        return value;
      },
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  },
  (err) => {
    if (err) return console.error('something went wrong loading', err);
  },
);

export default i18n;
