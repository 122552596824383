import React, { useState } from 'react';
import {
  Grid, IconButton, Tooltip, Typography,
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { AddCircleOutline } from '@material-ui/icons';

import { useTranslate } from '../../components/translations/Translate';
import Connections, { ApiEndpoints } from '../../components/connections/Connections';
import useFetch from '../../components/connections/hooks/useFetch';
import useValuesDialog from '../../components/dialogs/hooks/useValuesDialog';
import Loading from '../../components/loading/Loading';
import useSnackbar from '../../components/snackbars/hooks/useSnackbar';
import ErrorText from '../../components/validations/ErrorText';
import SpeedPagesEnums from './enums/SpeedPagesEnums';
import MediaCard from '../../components/cards/MediaCard';
import UrlEnums from '../../components/connections/enums/UrlEnums';

const SpeedPages = () => {
  const { t } = useTranslate(['common', 'speedPages']);
  const { result, runFetch } = useFetch({ url: ApiEndpoints.getMyProjects });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  if (result.loading) return <Loading />;
  const { ValuesDialog, openDialog } = useValuesDialog();
  const { createSuccessSnackbar, createErrorSnackbar } = useSnackbar();
  const { projects = [] } = result.payload || {};

  const addProject = async (pageParams) => {
    setError(null);
    setLoading(true);
    const res = await Connections.postRequest(ApiEndpoints.addProject, pageParams);
    if (res.ok) {
      createSuccessSnackbar(t('successfullyCreatedProject'));
      runFetch();
    } else {
      createErrorSnackbar(t('errorOnCreatingProject'));
      if (res.errorMessage) {
        setError(res.errorMessage);
      }
    }
    setLoading(false);
  };

  const addNewProject = () => {
    openDialog({
      title: t('speedPages:modal.title'),
      text: t('speedPages:modal.enterDataForYourNewProject'),
      defaultValues: {
        type: SpeedPagesEnums.SPEED_PAGE,
        name: '',
        description: '',
      },
      inputs: [
        {
          inputType: 'text',
          props: {
            name: 'name',
            label: t('speedPages:modal.name'),
          },
        },
        {
          inputType: 'text',
          props: {
            name: 'description',
            label: t('speedPages:modal.description'),
          },
        },
      ],
    },
    projectValues => {
      addProject(projectValues).then();
    });
  };
  return (
    <div>
      <Helmet>
        <title>{t('speedPages:pageTitle')}</title>
        <meta name="description" content={t('speedPages:pageTitle')} />
      </Helmet>
      <Grid
        container
        justify="space-between"
      >
        <Grid item xs={6}>
          <br />
          <Typography variant="h1">{t('speedPages:pageTitle')}</Typography>
        </Grid>
        <Grid />
        <Grid item xs={2}>
          <Grid container justify="flex-end">
            <Tooltip title={t('speedPages:newProject')} placement="left">
              <IconButton color="secondary" onClick={addNewProject}>
                <AddCircleOutline fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {loading && <Loading />}
      {error && <ErrorText error={error} />}
      {ValuesDialog}
      <Grid container spacing={3}>
        {projects.map(w => w.type.toLowerCase() === 'speedpage' && (
          <Grid key={w.projectId} item xs={12} sm={12} md={6} lg={4}>
            <MediaCard
              {...w}
              menu={
              [
                {
                  type: 'link',
                  to: `${UrlEnums.VIEW_SPEEDPAGE_BASE}/${w.projectId}`,
                  label: t('common:edit', { format: 'capitalize' }),
                },
                {
                  type: 'link',
                  to: '/login',
                  label: t('common:settings', { format: 'capitalize' }),
                },
                { type: 'divider' },
                {
                  type: 'link',
                  to: '/login',
                  label: t('common:deactivate', { format: 'capitalize' }),
                },
                {
                  type: 'link',
                  to: `${UrlEnums.VIEW_SPEEDPAGE_BASE}/${w.projectId}`,
                  label: t('common:delete', { format: 'capitalize' }),
                },
              ]
            }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SpeedPages;
