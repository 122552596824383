import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslate } from '../../components/translations/Translate';
import ShopPricing from './ShopPricing';

const OrderProject = ({ match }) => {
  const { params } = match;
  const { projectId } = params;
  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title>{t('myProjects')}</title>
        <meta name="description" content={t('myProjects')} />
      </Helmet>
      <ShopPricing projectId={projectId} />
    </>
  );
};

export default OrderProject;
