import React from 'react';
import { useTranslate } from '../components/translations/Translate';

const NotFoundPage = () => {
  const { t } = useTranslate();
  return (
    <div>
      {t('app.pageNotFound')}
    </div>
  );
};

export default NotFoundPage;
