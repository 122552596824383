import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer as MuiDrawer } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import Sidebar from './Sidebar';
import { useSidebarState, useSetSidebarState } from '../../globalStore/hooks/globalHooks';

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
  menuButton: {
    position: 'sticky',
    // left: 220,
    top: 110, // toolbar-height + x
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(255,255,255,1)',
    borderColor: 'rgba(120,120,120,0.4)',
    borderWidth: 1,
    borderStyle: 'solid',
    '&:hover': {
      backgroundColor: '#a7f7cb',
      borderWidth: 1,
      borderStyle: 'solid',
    },
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
}));

export default function Drawer() {
  const classes = useStyles();
  const sidebarState = useSidebarState();
  const setSidebarState = useSetSidebarState();
  const { open } = sidebarState;

  const toggleDrawer = (openDrawer) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setSidebarState({ open: !openDrawer });
  };

  return (
    <>
      <MuiDrawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <div role="presentation">
          <Sidebar drawerState={open} toggleDrawer={toggleDrawer} />
        </div>
      </MuiDrawer>
      <IconButton
        edge="start"
        className={classes.menuButton}
        aria-label="open drawer"
        onClick={toggleDrawer(open)}
      >
        { open === false ? <ChevronRight fontSize="small" /> : <ChevronLeft fontSize="small" />}
      </IconButton>
    </>
  );
}
