import { useSetRecoilState, useRecoilValue } from 'recoil';
import { sidebarStore, globalStore } from '../stores/globalStore';

export const useSetSidebarState = () => useSetRecoilState(sidebarStore);

export const useSidebarState = () => useRecoilValue(sidebarStore);

export const useSetGlobalState = () => useSetRecoilState(globalStore);

export const useGlobalState = () => useRecoilValue(globalStore);

export default {};
