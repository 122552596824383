import React from 'react';
import {
  Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip,
} from '@material-ui/core';
import {
  Apps, Help, ImportantDevices, LocalMall, Settings, SupervisorAccount, Web,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslate } from '../../translations/Translate';
import Authorized from '../../../screens/auth/Authorized';
import CustomLink from '../../inputs/CustomLink';
import UrlEnums from '../../connections/enums/UrlEnums';
import SidebarHeader from './SidebarHeader';

const useStyles = makeStyles(theme => ({
  gridWrapper: {
    paddingTop: 70,
    justifyContent: 'flex-start',
  },
  alignTop: {
    alignItems: 'flex-start',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar, // necessary for content to be below app bar
    justifyContent: 'flex-end',
    position: 'relative',
  },
  sidebarLogo: {
    position: 'absolute',
    left: '20px',
  },
}));

const Sidebar = ({ drawerState }) => {
  const classes = useStyles();
  const { t } = useTranslate(['common', 'navigation']);
  return (
    <>
      <Grid container direction="column" className={classes.gridWrapper}>
        <SidebarHeader
          image="https://randomuser.me/api/portraits/med/women/76.jpg"
          line1="Emma Johnson"
          line2="IT Agency BiT & ByTe"
          collapsed={!drawerState}
        />
        <Grid item className={clsx(['classes.drawerHeader'])}>
          <List>
            <CustomLink plain to={UrlEnums.MAIN}>
              <ListItem button key="home">
                <ListItemIcon>
                  <Tooltip title={t('navigation:sidebar.home')} placement="top" arrow>
                    <Apps />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t('navigation:sidebar.home')} />
              </ListItem>
            </CustomLink>
            <Authorized authenticated>
              <CustomLink plain to={UrlEnums.SPEEDPAGES}>
                <ListItem button key="pages">
                  <ListItemIcon>
                    <Tooltip title={t('navigation:sidebar.pages')} placement="top" arrow>
                      <ImportantDevices />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={t('navigation:sidebar.pages')} />
                </ListItem>
              </CustomLink>
              <CustomLink plain to={UrlEnums.SHOPS}>
                <ListItem button key="shops">
                  <ListItemIcon>
                    <Tooltip title={t('navigation:sidebar.shops')} placement="top" arrow>
                      <LocalMall />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={t('navigation:sidebar.shops')} />
                </ListItem>
              </CustomLink>
              <ListItem button key="settings">
                <ListItemIcon>
                  <Tooltip title={t('settings')} placement="top" arrow>
                    <Settings />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t('settings')} />
              </ListItem>
            </Authorized>
            <Authorized adminOnly>
              <Divider />
              <CustomLink plain to={UrlEnums.ALL_USERS}>
                <ListItem button key="allUsers">
                  <ListItemIcon>
                    <Tooltip title={t('users.all')} placement="top" arrow>
                      <SupervisorAccount />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={t('users.all')} />
                </ListItem>
              </CustomLink>
              <CustomLink plain to={UrlEnums.MAIN}>
                <ListItem button key="allPages">
                  <ListItemIcon>
                    <Tooltip title={t('pages.all')} placement="top" arrow>
                      <Web />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={t('pages.all')} />
                </ListItem>
              </CustomLink>
            </Authorized>
          </List>
        </Grid>
        <Grid item>
          <CustomLink plain to={UrlEnums.MAIN}>
            <ListItem button key="help">
              <ListItemIcon>
                <Tooltip title={t('help')} placement="top" arrow>
                  <Help />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('help')} />
            </ListItem>
          </CustomLink>
        </Grid>
      </Grid>
    </>
  );
};

export default Sidebar;
