import React, { useEffect, useRef, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/de';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Connections, { ApiEndpoints } from '../../components/connections/Connections';
import Loading from '../../components/loading/Loading';
import BasicConfig from '../../components/config/BasicConfig';
import CustomButton from '../../components/inputs/CustomButton';

function postRequestToVisualizer(sendData) {
  // BASE64 encoded String for POST
  const postData = Buffer.from(JSON.stringify(sendData)).toString('base64');
  if (document) {
    let form = null;
    const pElem = document.getElementById('leftSide');
    const previewData = document.getElementById('previewData');
    if (previewData) {
      previewData.setAttribute('value', postData);
    } else {
      form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', BasicConfig.VISUALIZER_SPEEDPAGE);
      form.setAttribute('target', 'visualizerFrame');

      const formData = document.createElement('input');
      formData.setAttribute('type', 'hidden');
      formData.setAttribute('name', 'preview');
      formData.setAttribute('id', 'previewData');
      formData.setAttribute('value', postData);
      form.appendChild(formData);
      pElem.appendChild(form);
    }
    pElem.getElementsByTagName('form')[0].submit();
  }
}

const useStyles = makeStyles(() => ({
  mobileDeviceFrame: {
    border: '40px solid #ddd',
    overflow: 'hidden',
    transform: 'rotateX(0deg) rotateY(0deg) rotateZ(0deg)',
    boxShadow: '0px 3px 0 #bbb, 0px 4px 0 #bbb, 0px 5px 0 #bbb, 0px 7px 0 #bbb, 0px 10px 20px #666',
    transition: 'all 0.5s ease',
    borderWidth: '55px 7px',
    borderRadius: '40px',
    margin: '1.3em',
    maxHeight: '85%',
    maxWidth: '95%',
  },
}));

const DemoSpeedPageViewer = ({ match }) => {
  const { t } = useTranslation(['speedPages']);
  const classes = useStyles();
  const iframeRef = useRef(null);
  const { params: { projectId } } = match;

  const projectName = 'test';
  const placeholderPage = 'https://just-sell.online';

  const [error] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [prevData, setPrevData] = useState(null); // save
  const [loading, setLoading] = useState(true);
  const [iframeMounted, setIframeMounted] = useState(false);

  const getSettings = async () => {
    setLoading(true);
    try {
      const res = await Connections.postRequest(ApiEndpoints.getProjectById, { projectId });
      if (res.ok) {
        const { data: { project: { configuration } } } = res;
        postRequestToVisualizer(configuration);
        setInitialData(configuration); // used for discard all changes
        setPrevData(configuration); // used to check if Data changed in JSON-Window depending on the previous request
      }
    } catch (e) {
      console.log('error while fetching Project-Configuration for ID: $projectId', e);
    }
  };

  useEffect(() => {
    if (initialData && iframeMounted) {
      if (!iframeRef.current) return;
      setLoading(false);
    }
  }, [initialData, iframeMounted]);

  useEffect(() => {
    getSettings().then();
  }, [projectId]);
  if (error) {
    return <div> {error}</div>;
  }

  return (
    <>
      <Helmet>
        <title>{projectName}</title>
      </Helmet>
      <Grid
        container
        justify="space-between"
      >
        <Grid item xs={6}>
          <br />
          <Typography variant="h1">{projectName}</Typography>
        </Grid>
        <Grid />
        <Grid item xs={2}>
          <Grid container justify="flex-end" />
        </Grid>
      </Grid>
      {loading && <Loading />}
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(250px, 45%) 1fr',
      }}
      >
        <div id="leftSide">
          <JSONInput
            style={{
              body: {
                display: 'flex',
                flexWrap: 'nowrap',
                overflowX: 'auto',
              },
              contentBox: {
                flex: '0 0 auto',
              },
            }}
            placeholder={initialData} // data to display
            theme="light_mitsuketa_tribute"
            locale={locale}
            height="calc(100vh - 180px)"
            width="100%"
            onBlur={(p) => {
              if (JSON.stringify(prevData) !== JSON.stringify(p.jsObject)) {
                if (p.error === false) {
                  postRequestToVisualizer(p.jsObject);
                  setPrevData(p.jsObject);
                } else {
                  console.log('error in code');
                }
              }
            }}
          />
          <CustomButton
            buttonTheme="secondary"
            text="Click here to update preview"
          >
            {t('preview.refresh')}
          </CustomButton>
        </div>
        <iframe
          className={classes.mobileDeviceFrame}
          name="visualizerFrame"
          target="speedpageVisualizer"
          ref={iframeRef}
          title="speedPagePreview"
          src={placeholderPage}
          seamless
          width="100%"
          height="90%"
          frameBorder="0"
          onLoad={() => setIframeMounted(true)}
        />
      </div>
    </>
  );
};

export default DemoSpeedPageViewer;
